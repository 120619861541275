import { CSSProperties } from 'react';
import { Button } from '@components/Button';

export type DatePickerFooterProps = {
    canSave: boolean;
    showFooter?: boolean;
    cancelLabel?: string;
    acceptLabel?: string;
    containerStyles?: CSSProperties;
    cancelFunction: () => void;
    acceptFunction: () => void;
};

export function DatePickerFooter({
    canSave,
    showFooter = false,
    cancelLabel,
    acceptLabel,
    containerStyles,
    cancelFunction,
    acceptFunction,
}: DatePickerFooterProps) {
    if (!showFooter) {
        return null;
    }

    return (
        <div
            className="border-coremedia-grey-300 col-span-2 mt-1 flex h-10 items-center justify-end gap-1 border-0 border-t border-solid"
            style={containerStyles}
        >
            <Button variant="clean" size="sm" onClick={cancelFunction}>
                {cancelLabel ?? 'Cancel'}
            </Button>
            <Button variant="clean" size="sm" onClick={acceptFunction} disabled={!canSave}>
                {acceptLabel ?? 'Accept'}
            </Button>
        </div>
    );
}
