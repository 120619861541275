import {
    useRef,
    useEffect,
    forwardRef,
    isValidElement,
    useImperativeHandle,
    type CSSProperties,
    type HTMLAttributes,
    type ReactNode,
    type JSXElementConstructor,
    type ComponentProps,
} from 'react';
import { isFunction } from '@sidetalk/helpers';
import { tv } from '@lib/tailwind-variants';
import { Icon } from '@components/Icon';
import { InlineEdit, InlineEditBasicProps } from '@components/InlineEdit';
import { useDraggableItem } from '../../contexts/draggableItem';
import { useDraggableList } from '../../contexts/draggableList';
import { DraggableButton, CollapseButton, ChildCountBadge, ActionButton } from '../../styles';
import type { CustomItemValueProps } from '../../types';

const sortableItemSlots = tv({
    slots: {
        itemContainer: [
            'flex pl-[--spacing-depth]',
            'data-[disabled-interaction=true]:pointer-events-none data-[disabled-selection=true]:select-none',
        ],
        item: [
            'bg-coremedia-white relative flex h-[3.125rem] grow items-center p-2.5 text-[0.8125rem] font-bold',
            'border-coremedia-grey-200 border-b',
        ],
        valueContainer: [
            'group/draggable-item-value flex h-full grow items-center',
            'data-[is-title-editable=true]:text-coremedia-grey-700 text-coremedia-grey-300',
        ],
        disabledValue: 'flex w-full break-words',
        actionContainer: 'flex items-center gap-5 text-sm',
    },

    variants: {
        hasIndicator: {
            true: {},

            false: {},
        },

        isGhost: {
            true: {},
        },
    },

    compoundVariants: [
        {
            hasIndicator: true,
            isGhost: true,
            className: {
                itemContainer: 'z-10',

                item: [
                    'bg-coremedia-blue-500 !h-2 p-0 *:h-0 *:opacity-0',
                    'border-coremedia-blue-600 border',
                    'before:absolute before:-left-2 before:-top-1 before:size-3',
                    'before:bg-coremedia-white before:border-coremedia-blue-600 before:rounded-full before:border',
                ],
            },
        },
        {
            hasIndicator: false,
            isGhost: true,
            className: {
                item: 'border',
            },
        },
    ],

    defaultVariants: {
        hasIndicator: false,
        isGhost: false,
    },
});

const { itemContainer, item, valueContainer, disabledValue, actionContainer } = sortableItemSlots();

export type SortableItemProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
    children?: JSXElementConstructor<CustomItemValueProps> | ReactNode;
};

export const SortableItem = forwardRef<HTMLDivElement, SortableItemProps>(
    ({ className, style, children, ...rest }, forwardedRef) => {
        const rootRef = useRef<HTMLDivElement>(null);
        const {
            activeId,
            disabled,
            hasIndicator,
            projected,
            resources,
            orderedElementId,
            orderedElementOrder,
            indentationWidth,
            onUpdateElement,
            onItemEvents,
            onResetLabel,
        } = useDraggableList('SortableItem');
        const {
            element,
            isOverlay,
            disableInteraction,
            disableSelection,
            style: containerStyle,
            handleProps,
            dndSortableHook: { isDragging, setDroppableNodeRef, setDraggableNodeRef },
        } = useDraggableItem('SortableItem');
        const {
            id,
            title,
            hasEdit = false,
            hasOrder = false,
            hasFont = false,
            hasView = false,
            hasDelete = false,
            depth,
        } = element;

        useImperativeHandle(forwardedRef, () => rootRef.current!, []);

        const handleChangeTitle = (newTitle: string) => {
            if (newTitle !== element?.title) {
                onUpdateElement?.(element.id, { ...element.data, title: newTitle });
            }
        };

        useEffect(() => {
            setDraggableNodeRef(rootRef.current);
        }, [setDraggableNodeRef]);

        const sharableEditor = (props?: ComponentProps<InlineEditBasicProps>) => (
            <InlineEdit.Basic
                {...props}
                showRestoreButton={isFunction(onResetLabel)}
                value={element?.title}
                onSave={(value) => handleChangeTitle(value)}
                onRestore={() => onResetLabel?.(element.data)}
            />
        );

        const sharableDisabledValue = () => <div className={disabledValue()}>{title ?? ''}</div>;

        const spacingDepth = id === activeId && projected ? projected.depth : depth;
        const wrapperStyle: CSSProperties = {
            '--spacing-depth': `${indentationWidth * spacingDepth}px`,
        };

        const hasCustomItemValue = !!children;
        const defaultProps = {
            element: element.data,
            renderEditor: sharableEditor,
            renderDisabled: sharableDisabledValue,
            onUpdateElement,
            disabled,
        };
        const childProps = isValidElement<CustomItemValueProps>(children)
            ? {
                  ...children.props,
                  ...defaultProps,
              }
            : defaultProps;
        const CustomItemValue = (isValidElement(children) ? children.type : children) as JSXElementConstructor<
            Partial<CustomItemValueProps>
        >;
        const fontColor = element.style?.color;
        const stylesFont = fontColor
            ? {
                  '--item-color': `rgba(${fontColor?.r ?? 255}, ${fontColor?.g ?? 255}, ${fontColor?.b ?? 255}, ${fontColor?.a ?? 1})`,
              }
            : undefined;

        return (
            <li
                ref={setDroppableNodeRef}
                data-disabled-interaction={disableInteraction}
                data-disabled-selection={disableSelection}
                style={wrapperStyle}
                className={itemContainer({ isGhost: isDragging, hasIndicator })}
            >
                <div
                    ref={rootRef}
                    className={item({ isGhost: isDragging, hasIndicator, className })}
                    style={{ ...containerStyle, ...style }}
                    {...rest}
                >
                    <DraggableButton title={handleProps['aria-roledescription']} {...handleProps} />
                    <div
                        data-testid="draggable-item-value"
                        data-is-title-editable={!disabled}
                        className={valueContainer()}
                    >
                        <CollapseButton />
                        {hasCustomItemValue && <CustomItemValue {...childProps} />}
                        {!hasCustomItemValue && !disabled && sharableEditor()}
                        {!hasCustomItemValue && disabled && sharableDisabledValue()}
                    </div>
                    <div className={actionContainer()}>
                        {hasEdit && (
                            <ActionButton onClick={() => onItemEvents?.(element.data, 'edit')}>
                                <Icon type="byside" iconName="pencil" label={resources.editActionButton} />
                            </ActionButton>
                        )}
                        {hasOrder && (
                            <ActionButton
                                data-selected={id === orderedElementId}
                                onClick={() => onItemEvents?.(element.data, 'order')}
                            >
                                <Icon
                                    type="byside"
                                    iconName={
                                        id === orderedElementId && orderedElementOrder === 'asc'
                                            ? 'order-up'
                                            : 'order-down'
                                    }
                                    label={
                                        id === orderedElementId && orderedElementOrder === 'asc'
                                            ? resources.orderAscActionButton
                                            : resources.orderDescActionButton
                                    }
                                />
                            </ActionButton>
                        )}
                        {hasFont && (
                            <ActionButton
                                data-is-bold={element.style?.bold}
                                data-is-italic={element.style?.italic}
                                data-is-underline={element.style?.underline}
                                className={fontColor && 'text-[--item-color]'}
                                style={stylesFont}
                                onClick={() => onItemEvents?.(element.data, 'font')}
                            >
                                <Icon type="byside" iconName="font" label={resources.fontActionButton} />
                            </ActionButton>
                        )}
                        {hasView && (
                            <ActionButton
                                data-is-visible={element?.visible}
                                data-selected={element.selected}
                                onClick={() => onItemEvents?.(element.data, 'view')}
                            >
                                <Icon type="byside" iconName="export-rule" label={resources.viewActionButton} />
                            </ActionButton>
                        )}
                        {hasDelete && !disabled && (
                            <ActionButton
                                onClick={() => onItemEvents?.(element.data, 'delete')}
                                data-testid="draggable-item-action-delete"
                            >
                                <Icon type="byside" iconName="trash" label={resources.deleteActionButton} />
                            </ActionButton>
                        )}
                    </div>
                    {isOverlay && <ChildCountBadge />}
                </div>
            </li>
        );
    },
);

SortableItem.displayName = 'DraggableList.SortableItem';
