import { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { CustomRoot, CustomScrollbar, CustomThumb, CustomViewport } from './styles';

export type CustomScrollAreaRootProps = ComponentPropsWithRef<typeof CustomRoot>;

type BasicProps = ComponentPropsWithoutRef<typeof CustomRoot> & {
    hideVertical?: boolean;
    hideHorizontal?: boolean;
    viewportClassName?: string;
};

const Basic = forwardRef<ElementRef<typeof CustomRoot>, BasicProps>(
    (
        {
            hideVertical = false,
            hideHorizontal = false,
            type,
            scrollHideDelay,
            dir,
            children,
            asChild,
            viewportClassName,
            ...rest
        },
        forwardedRef,
    ) => (
        <CustomRoot ref={forwardedRef} type={type} scrollHideDelay={scrollHideDelay} dir={dir} {...rest}>
            <CustomViewport asChild={asChild} className={viewportClassName}>
                {children}
            </CustomViewport>
            {!hideVertical && (
                <CustomScrollbar orientation="vertical">
                    <CustomThumb />
                </CustomScrollbar>
            )}
            {!hideHorizontal && (
                <CustomScrollbar orientation="horizontal">
                    <CustomThumb />
                </CustomScrollbar>
            )}
        </CustomRoot>
    ),
);

Basic.displayName = 'CustomScrollArea.Basic';

export type CustomScrollAreaBasicProps = ComponentPropsWithRef<typeof Basic>;

export const CustomScrollArea = {
    Root: CustomRoot,
    Viewport: CustomViewport,
    Scrollbar: CustomScrollbar,
    Thumb: CustomThumb,
    Basic,
};
