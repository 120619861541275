import { ElementRef, forwardRef } from 'react';
import { Content, Arrow, type PopoverContentProps, type PopoverArrowProps } from '@radix-ui/react-popover';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { cn } from '@helpers/index';

const contentVariants = tv({
    base: [
        'z-popover bg-white',
        'max-h-[--radix-popover-content-available-height] max-w-[--radix-popover-content-available-width]',
    ],

    variants: {
        styleGuide: {
            new: [
                'rounded-md border border-solid border-gray-200',
                'min-w-[13.75rem] p-4',
                'shadow-popover/content transition-all will-change-[transform,opacity]',
                'data-[state=open]:data-[side=top]:animate-slideDownAndFade',
                'data-[state=open]:data-[side=right]:animate-slideLeftAndFade',
                'data-[state=open]:data-[side=left]:animate-slideRightAndFade',
                'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade',
            ],

            coremedia: [
                'text-coremedia-grey-700 rounded outline-none',
                'data-[state=open]:animation-fade-in shadow-[0_0_4px_0_rgba(0,0,0,0.36)]',
            ],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomContentProps = PopoverContentProps & VariantProps<typeof contentVariants>;

export const CustomContent = forwardRef<ElementRef<typeof Content>, CustomContentProps>(
    ({ styleGuide, className, ...rest }, forwardedRef) => (
        <Content ref={forwardedRef} className={contentVariants({ styleGuide, className })} {...rest} />
    ),
);

export const CustomArrow = forwardRef<ElementRef<typeof Arrow>, PopoverArrowProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Arrow
            ref={forwardedRef}
            className={cn('fill-white drop-shadow-[0_1px_0_rgb(0,0,0,0.18)]', className)}
            {...rest}
        />
    ),
);

CustomArrow.displayName = 'Popover.PopoverArrow';
