import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { TabsContentProps } from '@radix-ui/react-tabs';
import { CustomScrollArea } from '@components/CustomScrollArea';
import { cn } from '@helpers/index';
import { TabsContextProvider, TabsContextProviderProps } from './context';
import { CustomRoot, CustomList, CustomTrigger, RenderExtraContainer, CustomContent, ExtraValue } from './styles';

type RootProps = ComponentPropsWithoutRef<typeof CustomRoot> & TabsContextProviderProps;

const Root = forwardRef<ElementRef<typeof CustomRoot>, RootProps>(
    ({ styleGuide, alignment, layout, size, ...rest }, forwardedRef) => (
        <TabsContextProvider styleGuide={styleGuide} alignment={alignment} layout={layout} size={size}>
            <CustomRoot ref={forwardedRef} {...rest} />
        </TabsContextProvider>
    ),
);

Root.displayName = 'Tabs.Root';

export type TabsRootProps = typeof Root;

type CustomTabsContentScrollableProps = Pick<TabsContentProps, 'value' | 'forceMount'> &
    ComponentPropsWithoutRef<typeof CustomScrollArea.Basic>;

const ContentScrollable = forwardRef<ElementRef<typeof CustomContent>, CustomTabsContentScrollableProps>(
    ({ value, forceMount = true, children, className, ...rest }, forwardedRef) => (
        <CustomContent ref={forwardedRef} value={value} forceMount={forceMount}>
            <div className="absolute inset-0">
                <CustomScrollArea.Basic className={cn('size-full', className)} {...rest}>
                    {children}
                </CustomScrollArea.Basic>
            </div>
        </CustomContent>
    ),
);

ContentScrollable.displayName = 'Tabs.ContentScrollable';

export const Tabs = {
    Root,
    List: CustomList,
    Trigger: CustomTrigger,
    Content: CustomContent,
    ContentScrollable,
    RenderExtraContainer,
    ExtraValue,
};
