import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ActiveModifiers, DateRange, DayPickerRangeProps } from 'react-day-picker';
import { deepClone } from '@sidetalk/helpers';
import { DateFormat, DatePickerRanges, DatePickerWithDefaultRangesProps } from '@components/DatePicker';
import {
    buildIsDayDisabled,
    getDateFormatsOnOldStyle,
    getDateLocale,
    getInitialRange,
    onSelectedRangeChangeTime,
    preserveDayTime,
    sortRangeDates,
} from '@components/DatePicker/helpers';
import { Popover } from '@components/Popover';
import { DayType } from '@components/DatePicker/types';
import { Icon } from '@components/Icon';
import { CustomTriggerButton } from '@components/DatePicker/styles';
import { Button } from '@components/Button';
import { CustomScrollArea } from '@components/CustomScrollArea';
import { cn } from '@helpers/index';
import { DatePickerRoot } from '../Root';
import { DateTimePicker } from '../DateTimePicker';
import { DatePickerFooter } from '../Footer';
import { CustomRangeTriggerButton } from './styles';

export function DatePickerWithDefaultRanges({
    selected = getInitialRange(),
    initialDefaultOption,
    onSelect,
    ranges,
    locale,
    customRangeLabel = 'Custom',
    options,
    styleGuide = 'coremedia',
    variant = 'secondary',
    timePicker = false,
    timePickerSeconds = false,
    maxDate,
    minDate,
    cancelLabel,
    acceptLabel,
    disabledPopover = false,
    isInvalidDate,
    showLocalizedDate = false,
    ...rest
}: DatePickerWithDefaultRangesProps) {
    const addCustomRangeOption: DatePickerRanges = {
        ...ranges,
        [customRangeLabel]: [],
    };

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [previousSelectedRange, setPreviousSelectedRange] = useState(() => deepClone(selected));
    const [selectedRange, setSelectedRange] = useState(selected);
    const [defaultOptions, setDefaultOptions] = useState<string>(
        initialDefaultOption ?? Object.keys(addCustomRangeOption)[0],
    );
    const [persistedValue, setPersistedValue] = useState<
        | [
              day: DayPickerRangeProps['selected'],
              selectedDay: Date,
              activeModifiers: ActiveModifiers,
              e: MouseEvent<Element, globalThis.MouseEvent>,
          ]
        | null
    >([
        {
            from: selected?.from,
            to: selected?.to,
        } as DayPickerRangeProps['selected'],
        selected!.to!,
        {} as ActiveModifiers,
        {} as MouseEvent<Element, globalThis.MouseEvent>,
    ]);

    const isDayDisabled = useMemo(() => buildIsDayDisabled(minDate, maxDate), [minDate, maxDate]);

    const hasTimePicker = timePicker;
    const dateLocale = options?.customDateFnsLocale ?? getDateLocale(locale);
    const isCustomRange = defaultOptions === customRangeLabel;

    const onChangeTime = useCallback(
        (time: Date, day: DayType) => {
            setSelectedRange((oldState) => onSelectedRangeChangeTime(oldState, time, day, !!timePickerSeconds));
        },
        [timePickerSeconds],
    );

    const handleDefaultOption = (option: string, e: MouseEvent) => {
        setDefaultOptions(option);

        if (typeof onSelect !== 'undefined') {
            if (option && option !== customRangeLabel) {
                onSelect(
                    {
                        from: addCustomRangeOption[option][0],
                        to: addCustomRangeOption[option][1],
                    },
                    option as unknown as Date,
                    {},
                    e,
                );

                setSelectedRange({
                    from: addCustomRangeOption[option][0],
                    to: addCustomRangeOption[option][1],
                });
            }
        }
    };

    const handleRangeSelection = (option: string, e: MouseEvent) => {
        setDefaultOptions(option);

        if (typeof onSelect !== 'undefined') {
            if (option && option !== customRangeLabel) {
                const newRange = {
                    from: addCustomRangeOption[option][0],
                    to: addCustomRangeOption[option][1],
                };

                onSelect(newRange, option as unknown as Date, {}, e);
                setSelectedRange(newRange);
                setIsPopoverOpen(false);
            }

            if (option === customRangeLabel && persistedValue !== null) {
                const newPersistedValue = deepClone(persistedValue);
                newPersistedValue[0] = selectedRange;
                setPersistedValue(
                    newPersistedValue as [
                        day: DayPickerRangeProps['selected'],
                        selectedDay: Date,
                        activeModifiers: ActiveModifiers,
                        e: MouseEvent<Element, globalThis.MouseEvent>,
                    ],
                );
            }
        }
    };

    const handleSelectedRange = (
        __range: DateRange | undefined,
        selectedDate: Date,
        activeModifiers: ActiveModifiers,
        e: MouseEvent,
    ) => {
        setSelectedRange(() => {
            const newSelectRange = {
                from:
                    ((selectedRange?.to && selectedRange.from) ?? !selectedRange?.from)
                        ? selectedDate
                        : selectedRange?.from,
                to: !selectedRange?.to && selectedRange?.from ? selectedDate : undefined,
            };

            const sortedSelectRange = sortRangeDates(newSelectRange)!;
            sortedSelectRange.from = preserveDayTime(sortedSelectRange?.from, selectedRange?.from);
            sortedSelectRange.to = preserveDayTime(sortedSelectRange?.to, selectedRange?.to, 'to');

            if (
                onSelect &&
                sortedSelectRange?.from &&
                sortedSelectRange?.to &&
                !hasTimePicker &&
                styleGuide !== 'coremedia'
            ) {
                setPreviousSelectedRange(sortedSelectRange);
                onSelect(sortedSelectRange, selectedDate, activeModifiers, e);
            }

            setPersistedValue([sortedSelectRange as DayPickerRangeProps['selected'], selectedDate, activeModifiers, e]);

            return sortedSelectRange;
        });
    };

    const getOldDateFormatted = () => {
        const { formatDate, formatDateAndTime, formatDateAndTimeWithSeconds } =
            getDateFormatsOnOldStyle(showLocalizedDate);

        if (
            previousSelectedRange?.from?.getSeconds() === 0 &&
            previousSelectedRange?.from?.getMinutes() === 0 &&
            previousSelectedRange?.from?.getHours() === 0 &&
            previousSelectedRange?.to?.getSeconds() === 59 &&
            previousSelectedRange?.to?.getMinutes() === 59 &&
            previousSelectedRange?.to?.getHours() === 23
        ) {
            return `${format(previousSelectedRange?.from || 0, formatDate, {
                locale: dateLocale,
            })} - ${format(previousSelectedRange?.to || 0, formatDate, { locale: dateLocale })}`;
        }

        if (timePickerSeconds) {
            return `${format(previousSelectedRange?.from ?? 0, formatDateAndTimeWithSeconds, {
                locale: dateLocale,
            })} - ${format(
                previousSelectedRange?.to ?? previousSelectedRange?.from ?? 0,
                formatDateAndTimeWithSeconds,
                { locale: dateLocale },
            )}`;
        }

        if (timePicker) {
            return `${format(previousSelectedRange?.from ?? 0, formatDateAndTime, {
                locale: dateLocale,
            })} - ${format(previousSelectedRange?.to ?? previousSelectedRange?.from ?? 0, formatDateAndTime, {
                locale: dateLocale,
            })}`;
        }

        return `${format(previousSelectedRange?.from ?? 0, formatDate, {
            locale: dateLocale,
        })} - ${format(previousSelectedRange?.to ?? previousSelectedRange?.from ?? 0, formatDate, {
            locale: dateLocale,
        })}`;
    };

    const getDateFormatted = () => {
        if (selectedRange?.from && selectedRange?.to) {
            return `${format(selectedRange.from, DateFormat.longLocalizedDate)} - ${format(
                selectedRange.to,
                DateFormat.longLocalizedDate,
            )}`;
        }

        return customRangeLabel;
    };

    if (styleGuide === 'coremedia') {
        const shouldShowIcon = variant === 'boxed';
        const iconType = 'byside';
        const iconName = 'calendar';
        const canSaveDate = persistedValue !== null && !!selectedRange?.from && !!selectedRange?.to;

        return (
            <Popover.Root
                open={isPopoverOpen}
                onOpenChange={disabledPopover ? undefined : (open) => setIsPopoverOpen(open)}
            >
                <Popover.Trigger asChild>
                    <CustomTriggerButton disabled={disabledPopover} styleGuide="coremedia" variant={variant}>
                        {!isCustomRange ? defaultOptions : getOldDateFormatted()}
                        {shouldShowIcon && <Icon type={iconType} iconName={iconName} />}
                        {!shouldShowIcon && <Icon type="byside" iconName="arrow-small" />}
                    </CustomTriggerButton>
                </Popover.Trigger>
                <Popover.Content
                    styleGuide={styleGuide}
                    className={cn('grid grid-rows-1', isCustomRange && 'grid-cols-[auto_auto] grid-rows-[1fr_auto]')}
                >
                    <CustomScrollArea.Basic
                        className={cn(
                            isCustomRange && 'border-coremedia-grey-200 min-w-36 border-0 border-r border-solid',
                        )}
                    >
                        <div className="my-1 flex flex-col gap-1">
                            {Object.keys(addCustomRangeOption).map((key) => (
                                <CustomRangeTriggerButton
                                    key={key}
                                    styleGuide={styleGuide}
                                    variant={variant}
                                    onClick={(e) => handleRangeSelection(key, e)}
                                    data-state={defaultOptions === key ? 'open' : 'closed'}
                                >
                                    {key}
                                </CustomRangeTriggerButton>
                            ))}
                        </div>
                    </CustomScrollArea.Basic>
                    {isCustomRange && (
                        <CustomScrollArea.Basic className="flex flex-col">
                            <DatePickerRoot
                                mode="range"
                                selected={selectedRange}
                                onSelect={handleSelectedRange}
                                locale={dateLocale}
                                defaultMonth={selectedRange?.from}
                                numberOfMonths={2}
                                disabled={
                                    isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled
                                }
                                styleGuide={styleGuide}
                                {...rest}
                            />
                            <DateTimePicker
                                showTimePicker={hasTimePicker}
                                timeStart={selectedRange?.from ?? null}
                                timeEnd={selectedRange?.to}
                                onChangeTime={onChangeTime}
                                hasSeconds={!!timePickerSeconds}
                                isMultiple
                                styleGuide="coremedia"
                            />
                        </CustomScrollArea.Basic>
                    )}
                    <DatePickerFooter
                        showFooter={isCustomRange}
                        cancelFunction={() => {
                            setSelectedRange(deepClone(previousSelectedRange));
                            setIsPopoverOpen(false);
                        }}
                        acceptFunction={() => {
                            if (canSaveDate) {
                                onSelect?.(...persistedValue);
                                setPreviousSelectedRange(deepClone(selectedRange));
                                setIsPopoverOpen(false);
                            }
                        }}
                        containerStyles={{ margin: '0' }}
                        canSave={canSaveDate}
                        cancelLabel={cancelLabel}
                        acceptLabel={acceptLabel}
                    />
                    <Popover.Arrow />
                </Popover.Content>
            </Popover.Root>
        );
    }

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <Button styleGuide={styleGuide} variant="secondary" disabled={disabledPopover}>
                    {!isCustomRange ? defaultOptions : getDateFormatted()}
                </Button>
            </Popover.Trigger>
            <Popover.Content
                styleGuide={styleGuide}
                className={cn('grid grid-rows-1 gap-2', isCustomRange && 'grid-cols-[auto_auto]')}
            >
                <CustomScrollArea.Basic>
                    <div className="flex flex-col gap-1">
                        {Object.keys(addCustomRangeOption).map((key) => (
                            <Button
                                key={key}
                                styleGuide={styleGuide}
                                variant="secondary"
                                onClick={(e) => handleDefaultOption(key, e)}
                            >
                                {key}
                            </Button>
                        ))}
                    </div>
                </CustomScrollArea.Basic>
                {isCustomRange && (
                    <CustomScrollArea.Basic>
                        <DatePickerRoot
                            mode="range"
                            selected={selectedRange}
                            onSelect={handleSelectedRange}
                            locale={dateLocale}
                            numberOfMonths={2}
                            disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
                            styleGuide={styleGuide}
                            {...rest}
                        />
                    </CustomScrollArea.Basic>
                )}
            </Popover.Content>
        </Popover.Root>
    );
}
