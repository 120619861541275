import { ComponentPropsWithoutRef, ElementRef, forwardRef, HTMLAttributes } from 'react';
import { decoratePercentage } from '@sidetalk/helpers';
import { useHasOverflowed } from '@sidetalk/hooks';
import { Icon } from '@components/Icon';
import { Tooltip, tv } from '../..';
import type { BysideClassNamesIcons } from '@components/Icon';

const metricsSlots = tv({
    slots: {
        container: 'bo-scrollbar flex size-full flex-wrap content-start overflow-y-auto text-black',
        metricItem: [
            'bg-coremedia-white flex h-full max-h-32 min-w-52 flex-1 flex-col items-center justify-center gap-2.5 p-4',
            'border-coremedia-grey-200',
        ],
        labelContainer: 'font-regular w-full text-center text-sm text-black',
        label: 'truncate font-[inherit] text-[length:inherit] text-[color:inherit]',
        valueContainer: 'flex items-center gap-2',
        valueIcon: 'text-xl',
        value: 'font-default m-0 text-2xl font-bold leading-tight',
        pillContainer: 'bg-coremedia-grey-400 flex h-5 items-center rounded-full px-3',
        pillLabel: 'text-xxs leading-base text-coremedia-white font-bold',
    },
    variants: {
        isPositive: {
            true: {
                pillContainer: 'bg-coremedia-green-500',
            },
        },
        isNegative: {
            true: {
                pillContainer: 'bg-coremedia-red-500',
            },
        },
        isInvertedColor: {
            true: {},
        },
    },
    compoundVariants: [
        {
            isInvertedColor: true,
            isPositive: true,
            className: {
                pillContainer: 'bg-coremedia-red-500',
            },
        },
        {
            isInvertedColor: true,
            isNegative: true,
            className: {
                pillContainer: 'bg-coremedia-green-500',
            },
        },
    ],
    defaultVariants: {
        isPositive: false,
        isNegative: false,
        isInvertedColor: false,
    },
});

const { container, metricItem, label, valueContainer, valueIcon, value, pillContainer, pillLabel, labelContainer } =
    metricsSlots();

type Metric = {
    iconName?: BysideClassNamesIcons;
    id: string;
    isColorInverted?: boolean;
    name: string;
    value: number | string;
    variation?: number;
};

type MetricProps = {
    metric: Metric;
};

type RootProps = HTMLAttributes<HTMLDivElement> & MetricProps;

const Root = forwardRef<HTMLDivElement, RootProps>(({ metric, className, children, ...rest }, forwardedRef) => (
    <Tooltip.Provider>
        <div key={metric.id} ref={forwardedRef} className={metricItem({ className })} {...rest}>
            {children}
        </div>
    </Tooltip.Provider>
));

Root.displayName = 'Metrics.Root';

const Label = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, children, ...rest }, forwardedRef) => {
        const [labelRef, hasLabelOverflowed] = useHasOverflowed<HTMLDivElement>();

        return (
            <Tooltip.Basic content={hasLabelOverflowed ? children : undefined}>
                <div ref={forwardedRef} className={labelContainer({ className })} {...rest}>
                    <h4 ref={labelRef} className={label()}>
                        {children}
                    </h4>
                </div>
            </Tooltip.Basic>
        );
    },
);

Label.displayName = 'Metrics.Label';

const ValueContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, children, ...rest }, forwardedRef) => (
        <div ref={forwardedRef} className={valueContainer({ className })} {...rest}>
            {children}
        </div>
    ),
);

ValueContainer.displayName = 'Metrics.ValueContainer';

type ValueIconProps = Pick<Metric, 'iconName'> & {
    className?: string;
    children?: React.ReactNode;
};

function ValueIcon({ iconName, className, children }: ValueIconProps) {
    if (children) {
        return children;
    }

    if (!iconName) {
        return null;
    }

    return (
        <Icon type="byside" data-testid="metric-value-icon" iconName={iconName} className={valueIcon({ className })} />
    );
}

ValueIcon.displayName = 'Metrics.ValueIcon';

const Value = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, children, ...rest }, forwardedRef) => (
        <p ref={forwardedRef} className={value({ className })} {...rest}>
            {children}
        </p>
    ),
);

Value.displayName = 'Metrics.Value';

const Variation = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement> & Pick<Metric, 'variation' | 'isColorInverted'>
>(({ variation, isColorInverted = false, className, children, ...rest }, forwardedRef) => {
    if (typeof variation === 'undefined') {
        return null;
    }

    return (
        <div
            ref={forwardedRef}
            className={pillContainer({
                isPositive: variation > 0,
                isNegative: variation < 0,
                isInvertedColor: isColorInverted ?? false,
                className,
            })}
            {...rest}
        >
            <span className={pillLabel()}>{children ?? decoratePercentage(variation)}</span>
        </div>
    );
});

Variation.displayName = 'Metrics.Variation';

const Basic = forwardRef<ElementRef<typeof Root>, ComponentPropsWithoutRef<typeof Root> & MetricProps>(
    ({ metric, className, ...rest }, forwardedRef) => {
        return (
            <Root ref={forwardedRef} metric={metric} className={className} {...rest}>
                <Label>{metric.name}</Label>
                <ValueContainer>
                    <ValueIcon iconName={metric.iconName}></ValueIcon>
                    <Value>{metric.value}</Value>
                </ValueContainer>
                <Variation variation={metric.variation} isColorInverted={metric.isColorInverted}></Variation>
            </Root>
        );
    },
);

Basic.displayName = 'Metrics.Basic';

type MetricsListProps = HTMLAttributes<HTMLHeadingElement> & {
    metrics?: Metric[];
};

const MetricsList = forwardRef<HTMLHeadingElement, MetricsListProps>(
    ({ className, children, metrics, ...rest }, forwardedRef) => (
        <div ref={forwardedRef} className={container({ className })} {...rest}>
            {children ?? metrics?.map((metric) => <Basic key={metric.id} metric={metric} />)}
        </div>
    ),
);

MetricsList.displayName = 'Metrics.List';

type MetricsRootProps = typeof Root;
export type { Metric, MetricsListProps, MetricProps, MetricsRootProps };

export const Metrics = {
    Basic,
    Label,
    List: MetricsList,
    Root,
    Value,
    ValueContainer,
    ValueIcon,
    Variation,
};
