import { ElementRef, HTMLAttributes, forwardRef } from 'react';
import {
    Root,
    List,
    Trigger,
    Content,
    type TabsProps,
    type TabsListProps,
    type TabsTriggerProps,
    type TabsContentProps,
} from '@radix-ui/react-tabs';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { cn } from '@helpers/index';
import { useTabs } from './context';

const rootVariant = tv({
    base: 'flex size-full flex-col',

    variants: {
        styleGuide: {
            coremedia: 'data-[orientation=vertical]:flex-row',
            new: '',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const CustomRoot = forwardRef<ElementRef<typeof Root>, TabsProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide } = useTabs('Root');

    return <Root ref={forwardedRef} className={rootVariant({ styleGuide, className })} {...rest} />;
});

const listVariants = tv({
    base: 'flex items-center',

    variants: {
        styleGuide: {
            coremedia: [
                'border-coremedia-grey-200 border-0 border-solid',
                'data-[orientation=horizontal]:bg-transparent data-[orientation=horizontal]:px-5',
                'data-[orientation=horizontal]:border-b',
                'data-[orientation=vertical]:h-auto data-[orientation=vertical]:w-full data-[orientation=vertical]:max-w-[180px]',
                'data-[orientation=vertical]:bg-coremedia-grey-100 data-[orientation=vertical]:flex-col',
            ],

            new: [
                'relative mb-4 min-h-8 px-6',
                'before:absolute before:left-0 before:top-px before:h-px before:w-full before:bg-gray-100 before:content-[""]',
            ],
        },

        alignment: {
            left: 'justify-start',
            center: 'justify-center',
            right: 'justify-end',
            justify: 'justify-between',
        },

        layout: {
            nav: '',
            minimal: '',
            menu: '',
        },

        size: {
            xs: '',
            sm: '',
            md: '',
            lg: '',
        },

        hasBgColor: {
            true: 'data-[orientation=horizontal]:bg-coremedia-grey-100',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'coremedia',
            size: 'xs',
            className: [
                'data-[orientation=horizontal]:h-6 data-[orientation=horizontal]:min-h-6 data-[orientation=horizontal]:px-[15px]',
            ],
        },
        {
            styleGuide: 'coremedia',
            size: 'sm',
            className: ['data-[orientation=horizontal]:h-[30px] data-[orientation=horizontal]:min-h-[30px]'],
        },
        {
            styleGuide: 'coremedia',
            size: 'md',
            className: ['data-[orientation=horizontal]:h-[50px] data-[orientation=horizontal]:min-h-[50px]'],
        },
        {
            styleGuide: 'coremedia',
            size: 'lg',
            className: ['data-[orientation=horizontal]:h-[74px] data-[orientation=horizontal]:min-h-[74px]'],
        },
        {
            styleGuide: 'coremedia',
            layout: 'minimal',
            className: ['data-[orientation=horizontal]:gap-[15px] data-[orientation=horizontal]:border-b-0'],
        },
        {
            styleGuide: 'coremedia',
            layout: 'menu',
            className: [
                'data-[orientation=horizontal]:h-[60px] data-[orientation=horizontal]:min-h-[60px]',
                'data-[orientation=horizontal]:bg-coremedia-grey-100 data-[orientation=horizontal]:p-0',
            ],
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        alignment: 'left',
        layout: 'nav',
        size: 'md',
        hasBgColor: false,
    },
});

type CustomListProps = TabsListProps & Pick<VariantProps<typeof listVariants>, 'hasBgColor'>;

export const CustomList = forwardRef<ElementRef<typeof List>, CustomListProps>(
    ({ className, hasBgColor, ...rest }, forwardedRef) => {
        const { styleGuide, alignment, layout, size } = useTabs('List');

        return (
            <List
                ref={forwardedRef}
                className={listVariants({ styleGuide, alignment, layout, size, hasBgColor, className })}
                {...rest}
            />
        );
    },
);

CustomList.displayName = 'Tabs.List';

const triggerVariants = tv({
    base: [
        'box-border border-0 border-solid bg-transparent outline-none',
        'relative flex cursor-pointer items-center transition-colors',
        'disabled:cursor-not-allowed',
    ],

    variants: {
        styleGuide: {
            coremedia: [
                'border-coremedia-grey-200 text-coremedia-grey-400 gap-[5px] text-[13px] font-semibold',
                'enabled:hover:text-coremedia-grey-500 enabled:hover:[&_i]:text-coremedia-grey-500',
                'disabled:[&_i]:text-coremedia-grey-400',
                'data-[orientation=horizontal]:h-full data-[orientation=horizontal]:justify-center',
                'data-[orientation=horizontal]:data-[state=active]:text-coremedia-grey-700',
                'data-[orientation=vertical]:h-[65px] data-[orientation=vertical]:w-full',
                'data-[orientation=vertical]:pl-[15px] data-[orientation=vertical]:leading-[65px]',
                'data-[orientation=vertical]:border-b',
                'data-[orientation=vertical]:data-[state=active]:text-coremedia-grey-700',
                'data-[orientation=vertical]:data-[state=active]:bg-coremedia-white',
                '[&_i]:data-[state=active]:text-coremedia-grey-700',
            ],

            new: [
                'relative h-full min-h-8 gap-1 px-4 py-px',
                'text-xs font-bold text-gray-100',
                'border border-t-0 border-transparent',
                'enabled:hover:text-red-500 enabled:focus:text-red-500',
                'data-[state=active]:bg-white data-[state=active]:text-black',
                'data-[state=active]:rounded-b-xs data-[state=active]:border-gray-100',
                'data-[state=active]:before:opacity-100 data-[state=active]:after:opacity-100',
                'before:content-[""] after:content-[""]',
                'before:absolute after:absolute',
                'before:top-px after:top-px',
                'before:opacity-0 after:opacity-0',
                'before:h-[15px] after:h-[15px]',
                // pseudoElementSize: 5px
                'before:w-[5px] after:w-[5px]',
                // pseudoElementBorder: 1px
                'before:border-solid after:border-solid',
                'before:border-0 after:border-0',
                'before:border-t after:border-t',
                'before:border-gray-100 after:border-gray-100',
                // sizeToAlign: calc(-pseudoElementSize + -pseudoElementBorder)
                // sizeToAlign: calc(-5px + -1px)
                'before:left-[-6px] after:right-[-6px]',
                'before:border-r after:border-l',
                'before:rounded-tr-xs after:rounded-tl-xs',
                'before:shadow-[white_3px_-1px_0_0] after:shadow-[white_-3px_-1px_0_0]',
            ],
        },

        alignment: {
            left: '',
            center: '',
            right: '',
            justify: '',
        },

        layout: {
            nav: '',
            minimal: '',
            menu: '',
        },

        size: {
            xs: '',
            sm: '',
            md: '',
            lg: '',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'coremedia',
            size: 'xs',
            className: 'data-[orientation=horizontal]:text-xs data-[orientation=horizontal]:leading-[22px]',
        },
        {
            styleGuide: 'coremedia',
            size: 'sm',
            className: 'data-[orientation=horizontal]:leading-[24px]',
        },
        {
            styleGuide: 'coremedia',
            size: 'md',
            className: 'data-[orientation=horizontal]:leading-[48px]',
        },
        {
            styleGuide: 'coremedia',
            size: 'lg',
            className: 'data-[orientation=horizontal]:leading-[72px]',
        },
        {
            styleGuide: 'coremedia',
            alignment: 'justify',
            className: 'data-[orientation=horizontal]:flex-1',
        },
        {
            styleGuide: 'coremedia',
            alignment: 'right',
            className: 'data-[orientation=horizontal]:order-1',
        },
        {
            styleGuide: 'coremedia',
            alignment: 'left',
            className: 'data-[orientation=horizontal]:order-0',
        },
        {
            styleGuide: 'coremedia',
            layout: 'minimal',
            size: 'sm',
            className: 'data-[orientation=horizontal]:leading-[28px]',
        },
        {
            styleGuide: 'coremedia',
            layout: 'minimal',
            className: [
                'data-[orientation=horizontal]:px-[5px]',
                '[&_i]:data-[orientation=horizontal]:data-[state=active]:text-coremedia-blue-500',
            ],
        },
        {
            styleGuide: 'coremedia',
            layout: 'nav',
            className: [
                'data-[orientation=horizontal]:px-[30px]',
                'data-[orientation=horizontal]:after:content-none',
                'data-[orientation=horizontal]:data-[state=active]:after:content-[""]',
                'data-[orientation=horizontal]:after:absolute',
                'data-[orientation=horizontal]:after:bottom-0 data-[orientation=horizontal]:after:left-0',
                'data-[orientation=horizontal]:after:border-b-solid data-[orientation=horizontal]:after:w-full',
                'data-[orientation=horizontal]:after:bg-coremedia-grey-700 data-[orientation=horizontal]:after:h-1',
            ],
        },
        {
            styleGuide: 'coremedia',
            layout: 'menu',
            className: [
                'gap-0 data-[orientation=horizontal]:flex-1 data-[orientation=horizontal]:flex-col',
                'data-[orientation=horizontal]:border-r',
                'data-[orientation=horizontal]:p-0 data-[orientation=horizontal]:leading-[18px]',
                'data-[orientation=horizontal]:last:border-r-0',
                '[&_i]:data-[orientation=horizontal]:h-[35px] [&_i]:data-[orientation=horizontal]:text-xl [&_i]:data-[orientation=horizontal]:leading-[35px]',
                'data-[orientation=horizontal]:data-[state=active]:bg-coremedia-white',
                '[&_i]:data-[orientation=horizontal]:data-[state=active]:text-coremedia-blue-500',
            ],
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        alignment: 'left',
        layout: 'nav',
        size: 'md',
    },
});

export const CustomTrigger = forwardRef<ElementRef<typeof Trigger>, TabsTriggerProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide, alignment, layout, size } = useTabs('Trigger');

        return (
            <Trigger
                ref={forwardedRef}
                className={triggerVariants({ styleGuide, alignment, layout, size, className })}
                {...rest}
            />
        );
    },
);

CustomTrigger.displayName = 'Tabs.Trigger';

const renderExtraContainerVariant = tv({
    base: '',

    variants: {
        styleGuide: {
            coremedia: '',
            new: '',
        },

        alignment: {
            left: '',
            center: '',
            right: '',
            justify: '',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'coremedia',
            alignment: 'right',
            className: 'order-0 mr-auto',
        },
        {
            styleGuide: 'coremedia',
            alignment: 'left',
            className: 'order-1 ml-auto',
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        alignment: 'left',
    },
});

export const RenderExtraContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide, alignment } = useTabs('RenderExtraContainer');

        return (
            <div
                ref={forwardedRef}
                className={renderExtraContainerVariant({ styleGuide, alignment, className })}
                {...rest}
            />
        );
    },
);

RenderExtraContainer.displayName = 'Tabs.RenderExtraContainer';

export const ExtraValue = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <span ref={forwardedRef} className={cn('text-blue-500', className)} {...rest} />
    ),
);

ExtraValue.displayName = 'Tabs.ExtraValue';

export const CustomContent = forwardRef<ElementRef<typeof Content>, TabsContentProps>(
    ({ forceMount = true, className, ...rest }, forwardedRef) => (
        <Content
            ref={forwardedRef}
            className={cn('relative h-auto grow outline-none data-[state=inactive]:hidden', className)}
            forceMount={forceMount}
            {...rest}
        />
    ),
);

CustomContent.displayName = 'Tabs.Content';
